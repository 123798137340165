import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@shopify/polaris';
import { SketchPicker } from 'react-color';

export default class ColorField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false
    };
  }

  handleChange = color => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(color.hex);
    }
  };

  handleClick = () => {
    this.setState(({ displayColorPicker }) => ({ displayColorPicker: !displayColorPicker }));
  };

  handleClose = e => {
    if (e.type === 'click' || (e.type === 'keydown' && e.keycode === 27)) {
      this.setState({ displayColorPicker: false });
    }
  };

  render() {
    const { label, value } = this.props;
    const { displayColorPicker } = this.state;

    return (
      <div className="color-input" style={{ marginBottom: '10px' }}>
        <Button onClick={this.handleClick}>
          <Stack alignment="center" spacing="tight">
            <div
              style={{
                height: '2rem',
                width: '2rem',
                borderRadius: '0.3rem',
                background: value
              }}
            />
            <span>{label || 'Color'}</span>
          </Stack>
        </Button>
        {displayColorPicker && (
          <div
            style={{
              position: 'absolute',
              zIndex: '100'
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px'
              }}
              role="presentation"
              onClick={e => this.handleClose(e)}
            />
            <SketchPicker disableAlpha={true} color={value} onChange={this.handleChange} />
          </div>
        )}
      </div>
    );
  }
}

ColorField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};
