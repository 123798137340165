import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { EmptyState, Page, Tabs } from '@shopify/polaris';

import Settings from './components/Settings';
import Account from './components/Account';
import Dashboard from './components/Dashboard';
import ROUTES from './constants/routes';

const TABS = [
  {
    id: 'dashboard',
    content: 'Dashboard',
    panelID: 'dashboard',
    route: ROUTES.dashboard
  },
  {
    id: 'settings',
    content: 'Configure',
    panelID: 'settings',
    route: ROUTES.settings
  },
  {
    id: 'account',
    content: 'Account',
    panelID: 'account',
    route: ROUTES.account
  }
];

const findTabIndexByRoute = route => {
  for (let i = 0; i < TABS.length; i++) {
    if (TABS[i].route === route) {
      return i;
    }
  }
  return -1;
};

@inject('store')
@observer
class App extends Component {
  // this tells React to attach the `polaris` object to `this.context`
  static contextTypes = {
    polaris: PropTypes.object
  };

  constructor(props) {
    super(props);

    const tabIndexFromUrl = findTabIndexByRoute(window.location.pathname);

    this.state = {
      selectedTabIndex: tabIndexFromUrl > -1 ? tabIndexFromUrl : 0
    };

    this.props.store.startInitialFetch();
  }

  componentDidMount() {
    this.props.store.setAppBridge(this.context.polaris.appBridge);
    this.props.store.navigate = this.navigate;
  }

  navigate = route => {
    const newTabIndex = TABS.findIndex(tab => tab.route === route);
    if (newTabIndex > -1) {
      this.handleTabChange(newTabIndex);
    }
  };

  handleTabChange = selectedTabIndex => {
    this.props.store.setRoute(TABS[selectedTabIndex].route);
    this.setState({ selectedTabIndex });
  };

  renderTabContents(tabId) {
    switch (tabId) {
      case 'settings':
        return <Settings navigate={this.navigate} />;
      case 'account':
        return <Account />;
      case 'dashboard':
      default:
        return <Dashboard navigate={this.navigate} />;
    }
  }

  renderGlobalError() {
    return (
      <EmptyState
        heading="There is a problem loading this page"
        action={{ content: 'Reload this page', onAction: () => window.location.reload() }}
      >
        <p className="global-error">
          There is a technical problem that has prevented this page from loading. Try reloading this page or try again
          later.
        </p>
      </EmptyState>
    );
  }

  render() {
    const { store } = this.props;
    if (store.globalError) {
      return this.renderGlobalError();
    }

    const { selectedTabIndex } = this.state;
    const selectedTab = TABS[selectedTabIndex];

    const initialInstall = !store.planId;

    return (
      <Page title={selectedTab.content}>
        <div className="app">
          {initialInstall ? (
            // on initial install, render account contents without tabs (force merchant to select plan)
            this.renderTabContents('account')
          ) : (
            <Tabs tabs={TABS} selected={selectedTabIndex} onSelect={this.handleTabChange}>
              {this.renderTabContents(selectedTab.id)}
            </Tabs>
          )}
        </div>
      </Page>
    );
  }
}

App.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
