import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import {
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  Card,
  FormLayout,
  Frame,
  ContextualSaveBar,
  Select,
  Toast,
  Checkbox,
  Banner
} from '@shopify/polaris';
import ColorInput from './ColorInput';
import {
  STATUS_ENABLED,
  STATUS_DISABLED_AUTO,
  STATUS_DISABLED_INITIAL,
  STATUS_DISABLED_MANUAL
} from '../constants/status';
import WidgetPreview from './WidgetPreview';
import ROUTES from '../constants/routes';

@inject('store')
@observer
export default class Settings extends Component {
  componentDidMount() {
    this.props.store.startSettingsFetch();
  }

  getNextStatus(enabled) {
    const { widget_status: prevStatus } = this.props.store.origSettings;
    const disabledStatus = prevStatus === STATUS_ENABLED ? STATUS_DISABLED_MANUAL : prevStatus;
    return enabled ? STATUS_ENABLED : disabledStatus;
  }

  render() {
    const { store } = this.props;

    return (
      <div className="settings">
        <Frame>
          {store.unsavedChanges && (
            <ContextualSaveBar
              alignContentFlush
              message="Unsaved changes"
              saveAction={{ onAction: () => store.saveSettings(), loading: store.savingSettings }}
              discardAction={{ onAction: () => store.revertSettings() }}
            />
          )}

          {store.isLoadingSettings && (
            <Layout>
              <Layout.AnnotatedSection
                title={<SkeletonDisplayText size="small" />}
                description={<SkeletonBodyText lines="1" />}
              >
                <Card>
                  <Card.Section>
                    <SkeletonBodyText lines="3" />
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          )}

          {!store.isLoadingSettings && (
            <Layout>
              <Layout.AnnotatedSection title="General" description="Enable or disable the track order widget">
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <Checkbox
                        checked={store.settings.widget_status === STATUS_ENABLED}
                        disabled={store.settings.widget_status === STATUS_DISABLED_AUTO}
                        label="Enable widget"
                        onChange={enabled => store.setSetting('widget_status', this.getNextStatus(enabled))}
                      />
                      {store.origSettings.widget_status === STATUS_DISABLED_INITIAL && (
                        <Banner title="Initial Setup" status="info">
                          The order tracking widget starts out initially disabled to give you a chance to configure the
                          look and feel. After picking the desired color and position, check the &quot;Enabled
                          widget&quot; checkbox above to enable the widget to show up on your online store.
                        </Banner>
                      )}
                      {store.settings.widget_status === STATUS_DISABLED_AUTO && (
                        <Banner
                          title="Order Tracking Widget Disabled"
                          action={{ content: 'Upgrade Plan', onAction: () => this.props.navigate(ROUTES.account) }}
                          status="critical"
                        >
                          You have reached the hard limit of your plan and can no longer track orders for the current
                          billing cycle. The order tracking widget has been temporarily disabled until your billing
                          cycle resets.
                        </Banner>
                      )}
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection title="Look & Feel" description="Change how the track order widget looks.">
                <Card>
                  <Card.Section>
                    <FormLayout>
                      <ColorInput
                        label="Primary Color"
                        value={store.settings.colors.primary}
                        onChange={value => store.setSetting('colors', { ...store.settings.colors, primary: value })}
                      />
                      <Select
                        label="Position"
                        options={[{ label: 'Left', value: 'left' }, { label: 'Right', value: 'right' }]}
                        value={store.settings.position}
                        onChange={value => store.setSetting('position', value)}
                      />
                    </FormLayout>
                  </Card.Section>
                  <Card.Section>
                    <WidgetPreview position={store.settings.position} colors={store.settings.colors} />
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          )}

          {store.toastMessage && (
            <Toast content={store.toastMessage} error={store.toastError} onDismiss={() => store.clearToast()} />
          )}
        </Frame>
      </div>
    );
  }
}

Settings.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
};
