import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { action, computed, observable, flow } from 'mobx';
import { History } from '@shopify/app-bridge/actions';

import SolvvyApi from './util/solvvy-api';
import shopifyRedirect from './util/shopify-redirect';
import ROUTES from './constants/routes';
import { STATUS_DISABLED_INITIAL } from './constants/status';

export default class Store {
  @observable
  installing = false;

  @observable
  loadingInfo = true;

  @observable
  orgId = null;

  @observable
  planId = null;

  @observable
  widgetStatus = null;

  @observable
  limit = null;

  @observable
  usage = null;

  @observable
  nextBillingCycleResetsAt = null;

  @observable
  loadingDashboard = true;

  @observable
  monthlyMetrics = {};

  @observable
  loadingSettings = true;

  @observable
  savingSettings = false;

  @observable
  saveSettingsError = null;

  @observable
  settings = null;

  @observable
  origSettings = { ...this.settings };

  @observable
  loadingPlans = true;

  @observable
  plans = null;

  @observable
  changingPlanId = null;

  @observable
  completingChangePlan = null;

  @observable
  planChangeCompleted = false;

  @observable
  toastMessage = null;

  @observable
  toastError = false;

  @observable
  globalError = false;

  shop = null;

  solvvyApi = null;

  appBridge = null;

  constructor({ initialQueryParamsStr, solvvyApiHost, shop }) {
    this.initialQueryParamsStr = initialQueryParamsStr;
    this.solvvyApiHost = solvvyApiHost;
    this.shop = shop;
  }

  getSolvvyApi() {
    if (!this.solvvyApi) {
      this.solvvyApi = new SolvvyApi({
        solvvyApiHost: this.solvvyApiHost,
        shop: this.shop,
        shopifyAdminToken: btoa(this.initialQueryParamsStr)
      });
    }
    return this.solvvyApi;
  }

  @action
  setShop(shop) {
    this.shop = `${shop}.myshopify.com`;
  }

  install = flow(function*() {
    try {
      this.installing = false;
      shopifyRedirect(this.shop, yield this.getSolvvyApi().getAuthUrl());
    } catch (e) {
      console.error('error while fetching redirect install URL ', e);
      this.showGlobalError();
    } finally {
      this.installing = false;
    }
  });

  async startInitialFetch() {
    this.initialFetchPromise = this.fetchInfo();
    return this.initialFetchPromise;
  }

  async startSettingsFetch() {
    if (!this.fetchedSettings) {
      await this.initialFetchPromise;
      this.fetchSettings();
    }
  }

  async startAccountFetch() {
    if (!this.plans) {
      await this.initialFetchPromise;
      await this.fetchPlans();
    }
  }

  fetchInfo = flow(function*() {
    try {
      this.loadingInfo = true;
      const {
        org_id: orgId,
        plan_id: planId,
        next_billing_cycle_resets_at: nextBillingCycleResetsAt,
        limit,
        usage,
        widget_status: widgetStatus
      } = yield this.getSolvvyApi().getInfo();
      this.orgId = orgId;
      this.planId = planId;
      this.widgetStatus = widgetStatus;
      this.limit = limit;
      this.usage = usage;
      this.nextBillingCycleResetsAt = nextBillingCycleResetsAt;
    } catch (e) {
      console.error('error while fetching info', e);
      this.showGlobalError();
    } finally {
      this.loadingInfo = false;
    }
  });

  @action
  showSuccessToast(message) {
    this.toastMessage = message;
    this.toastError = false;
  }

  @action
  showErrorToast(message) {
    this.toastMessage = message;
    this.toastError = true;
  }

  @action
  clearToast() {
    this.toastMessage = null;
    this.toastError = false;
  }

  @action
  showGlobalError() {
    this.globalError = true;
  }

  @computed get isLoadingSettings() {
    return this.loadingInfo || this.loadingSettings;
  }

  @computed get isLoadingAccount() {
    return this.loadingInfo || this.loadingPlans || this.completingChangePlan;
  }

  fetchSettings = flow(function*() {
    try {
      this.loadingSettings = true;
      this.settings = yield this.getSolvvyApi().getOrgSettings(this.orgId);
      this.origSettings = { ...this.settings };
    } catch (e) {
      console.error('error while fetching settings', e);
      this.showGlobalError();
    } finally {
      this.loadingSettings = false;
    }
  });

  @action
  setSetting(name, value) {
    set(this.settings, name, value);
  }

  @computed
  get unsavedChanges() {
    return this.settings !== null && !isEqual(this.settings, this.origSettings);
  }

  @action
  revertSettings() {
    this.settings = { ...this.origSettings };
  }

  saveSettings = flow(function*() {
    try {
      this.savingSettings = true;
      yield this.getSolvvyApi().putOrgSettings(this.orgId, this.settings);
      this.showSuccessToast('Settings saved');
      this.origSettings = { ...this.settings };
      this.widgetStatus = this.settings.widget_status;
    } catch (e) {
      console.error('error while saving settings', e);
      this.showErrorToast('An unexpected server error occurred. Please try again later.');
    } finally {
      this.savingSettings = false;
    }
  });

  @computed get isLoadingDashboard() {
    return this.loadingInfo;
  }

  setAppBridge(appBridge) {
    this.appBridge = appBridge;
  }

  setRoute(route) {
    if (this.appBridge) {
      const history = History.create(this.appBridge);
      history.dispatch(History.Action.REPLACE, route);
    }
  }

  fetchPlans = flow(function*() {
    try {
      this.loadingPlans = true;
      this.plans = yield this.getSolvvyApi().getPlans(this.orgId);
    } catch (e) {
      console.error('error while fetching plans', e);
      this.showGlobalError();
    } finally {
      this.loadingPlans = false;
    }
  });

  handlePlanChangeSuccess = flow(function*(newPlanId) {
    this.showSuccessToast(this.planId ? 'Plan changed' : 'Plan selected');
    const oldPlanId = this.planId;
    this.planId = newPlanId;
    // fetch info for new plan
    this.initialFetchPromise = this.fetchInfo();
    yield this.initialFetchPromise;
    this.planChangeCompleted = true;

    if (!oldPlanId && this.widgetStatus === STATUS_DISABLED_INITIAL && this.navigate) {
      this.navigate(ROUTES.settings);
    } else {
      this.setRoute(ROUTES.account);
    }
  });

  changePlanStart = flow(function*(newPlanId) {
    try {
      this.changingPlanId = newPlanId;
      const { confirmation_url: confirmationUrl } = yield this.getSolvvyApi().changePlanStart(this.orgId, newPlanId);
      if (confirmationUrl) {
        shopifyRedirect(this.shop, confirmationUrl);
      } else {
        // must be changing to a plan that doesn't require billing confirmation
        yield this.handlePlanChangeSuccess(newPlanId);
        this.changingPlanId = null;
      }
    } catch (e) {
      this.changingPlanId = null;
      console.error('error while starting plan change', e);
      this.showGlobalError();
    }
  });

  changePlanComplete = flow(function*(newPlanId, chargeId) {
    try {
      this.completingChangePlan = true;
      // make sure initial fetch which looks up orgId completes
      yield this.initialFetchPromise;
      yield this.getSolvvyApi().changePlanComplete(this.orgId, newPlanId, chargeId);
      yield this.handlePlanChangeSuccess(newPlanId);
    } catch (e) {
      console.error('error while completing plan change', e);
      this.showGlobalError();
    } finally {
      this.completingChangePlan = false;
    }
  });
}
