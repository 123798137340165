import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import './Install.css';

@inject('store')
@observer
export default class Install extends Component {
  render() {
    const { store } = this.props;

    return (
      <div className="install">
        <h1>Solvvy Track Order Widget</h1>

        <p className="instructions">
          Type in your myshopify.com store name and click the install button to get started.
        </p>
        <div className="shop-input-group">
          <label htmlFor="shop">
            <span>https://</span>
            <input
              id="shop"
              className="shop-input"
              placeholder="example-store"
              value={store.shop}
              onChange={e => store.setShop(e.target.value)}
            />
            <span>.myshopify.com</span>
          </label>
        </div>
        <button className="install-app-button" disabled={store.installing} onClick={() => store.install()}>
          Install App
        </button>

        {store.globalError && <div className="error">An unexpected error occurred, please try again later.</div>}
      </div>
    );
  }
}

Install.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired
};
