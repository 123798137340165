import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { ProgressBar, SkeletonBodyText, SkeletonDisplayText, Heading, Banner } from '@shopify/polaris';

import ROUTES from '../constants/routes';
import { STATUS_DISABLED_AUTO } from '../constants/status';

@inject('store')
@observer
export default class Dashboard extends Component {
  renderUsageBanner() {
    const { usage, limit, widgetStatus } = this.props.store;

    let bannerStatus;
    let title;
    let description;
    if (widgetStatus === STATUS_DISABLED_AUTO) {
      bannerStatus = 'critical';
      title = 'Order Tracking Widget Disabled';
      description =
        'You have reached the hard limit of your plan and can no longer track orders for the current billing cycle. The order tracking widget has been temporarily disabled until your billing cycle resets.';
    } else if (usage >= limit) {
      bannerStatus = 'warning';
      title = 'Order Tracks Soft Limit Reached';
      description =
        'You have reached the soft limit of your plan and additional order tracks will incur a usage charge until your billing cycle resets.';
    } else {
      return undefined;
    }

    return (
      <Banner
        title={title}
        action={{ content: 'Upgrade Plan', onAction: () => this.props.navigate(ROUTES.account) }}
        status={bannerStatus}
      >
        {description}
      </Banner>
    );
  }

  render() {
    const { store } = this.props;

    return (
      <div className="dashboard">
        {store.isLoadingDashboard && (
          <>
            <SkeletonDisplayText />
            <SkeletonBodyText />
          </>
        )}
        {!store.isLoadingDashboard && (
          <>
            {this.renderUsageBanner()}

            <Heading>Monthly Track Order Usage</Heading>
            <p className="usage-description">
              Your billing cycle will reset on {new Date(store.nextBillingCycleResetsAt).toLocaleDateString()}. Note
              that multiple tracks for the same order within a 24 hour period are only counted once.
            </p>

            {store.limit && <ProgressBar progress={(store.usage / store.limit) * 100} />}
            <Heading>
              {store.usage}
              {store.limit ? ` / ${store.limit} ` : ' '}
              Order Tracks
            </Heading>
          </>
        )}
      </div>
    );
  }
}

Dashboard.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
};
