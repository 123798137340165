import axios from 'axios';

const DEFAULT_OPTIONS = {
  solvvyApiHost: 'https://api.solvvy.com',

  // base64 encoding of original query string from Shopify Admin iframe embedding (e.g. location.search)
  // should contain at least hmac and shop params
  shopifyAdminToken: undefined,

  // myshopify.com host (e.g. solvvy-dev.myshopify.com)
  shop: undefined
};
export default class SolvvyApi {
  constructor(options) {
    this.options = { ...DEFAULT_OPTIONS, ...options };
    const { solvvyApiHost, shopifyAdminToken, shop } = this.options;

    if (!shop) {
      throw Error('Missing required option: shop');
    }

    const headers = {};
    if (shopifyAdminToken) {
      headers.Authorization = `shopify-admin-bearer ${shopifyAdminToken}`;
    }

    this.axios = axios.create({ baseURL: `${solvvyApiHost}/v1/shopify`, headers });
  }

  async getAuthUrl() {
    const { solvvyApiHost, shop } = this.options;
    // don't use common axios instance since this endpoint must be anonymous
    const response = await axios.get(`${solvvyApiHost}/v1/shopify/authenticate`, { params: { shop } });
    return response.data.url;
  }

  async getInfo() {
    const response = await this.axios.get('/info');
    return response.data;
  }

  async getOrgSettings(orgId) {
    const response = await this.axios.get('/settings', { params: { org_id: orgId } });
    return response.data;
  }

  async putOrgSettings(orgId, settings) {
    const response = await this.axios.put('/settings', { org_id: orgId, ...settings });
    return response.data;
  }

  async getPlans(orgId) {
    const response = await this.axios.get('/plans', { params: { org_id: orgId } });
    return response.data;
  }

  async changePlanStart(orgId, newPlanId) {
    const response = await this.axios.post('/plan-change-start', { org_id: orgId, plan_id: newPlanId });
    return response.data;
  }

  async changePlanComplete(orgId, newPlanId, chargeId) {
    const response = await this.axios.post('/plan-change-complete', {
      org_id: orgId,
      plan_id: newPlanId,
      charge_id: chargeId
    });
    return response.data;
  }
}
