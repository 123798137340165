import 'react-app-polyfill/ie11'; // For IE 11 support
import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom';
import * as mobx from 'mobx';
import { Provider } from 'mobx-react';
import { AppProvider } from '@shopify/polaris';

import '@shopify/polaris/styles.css';
import './index.css';

import Store from './Store';
import App from './App';
import Install from './components/Install';

// load config from env
const { REACT_APP_SOLVVY_API_HOST, REACT_APP_SHOPIFY_API_KEY } = process.env;

// parse query params sent from Shopify admin iframe embedding
const initialQueryParamsStr = window.location.search;
const { shop, hmac } = queryString.parse(initialQueryParamsStr);
const isEmbedded = Boolean(hmac);

const store = new Store({
  initialQueryParamsStr,
  solvvyApiHost: REACT_APP_SOLVVY_API_HOST,
  shop
});

// setup mobx
mobx.configure({ enforceActions: 'observed' });
const renderDevTool = () => {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    window.store = store;
    const DevTools = require('mobx-react-devtools').default; // eslint-disable-line import/no-extraneous-dependencies
    return <DevTools />;
  }
  return undefined;
};

ReactDOM.render(
  <Provider store={store}>
    <>
      {renderDevTool()}
      {!isEmbedded && <Install />}
      {isEmbedded && (
        <AppProvider apiKey={REACT_APP_SHOPIFY_API_KEY} shopOrigin={shop}>
          <App />
        </AppProvider>
      )}
    </>
  </Provider>,
  document.getElementById('solvvy-app')
);
