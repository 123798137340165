import queryString from 'query-string';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import {
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  Card,
  Frame,
  Button,
  Toast,
  Stack,
  Heading
} from '@shopify/polaris';

@inject('store')
@observer
export default class Account extends Component {
  // this tells React to attach the `polaris` object to `this.context`
  static contextTypes = {
    polaris: PropTypes.object
  };

  componentDidMount() {
    const { store } = this.props;

    store.startAccountFetch();

    const { charge_id: chargeId, plan: newPlanId } = queryString.parse(window.location.search);
    if (chargeId && newPlanId && !store.completingChangePlan && !store.planChangeCompleted) {
      store.changePlanComplete(newPlanId, chargeId);
    }
  }

  renderPlan(plan) {
    const { store } = this.props;
    const isSelectedPlan = plan.id === store.planId;

    return (
      <Card.Section key={plan.id}>
        <Stack>
          <Stack.Item fill>
            <Heading>
              {plan.name} {plan.monthly_price ? <span className="price">${plan.monthly_price}/mo</span> : ''}
            </Heading>
            <p>{plan.description}</p>
            <p>{plan.usage_terms}</p>
          </Stack.Item>
          <Stack.Item>
            {isSelectedPlan ? (
              <span>Current Plan</span>
            ) : (
              <Button loading={store.changingPlanId === plan.id} onClick={() => store.changePlanStart(plan.id)}>
                Select
              </Button>
            )}
          </Stack.Item>
        </Stack>
      </Card.Section>
    );
  }

  render() {
    const { store } = this.props;
    return (
      <div className="account">
        <Frame>
          {store.isLoadingAccount && (
            <Layout>
              <Layout.AnnotatedSection
                title={<SkeletonDisplayText size="small" />}
                description={<SkeletonBodyText lines="1" />}
              >
                <Card>
                  <Card.Section>
                    <SkeletonBodyText lines="3" />
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          )}

          {!store.isLoadingAccount && (
            <Layout>
              <Layout.AnnotatedSection
                title="Plans"
                description="Pick the desired plan for your business. Note that multiple tracks for the same order within a 24 hour period are only counted once."
              >
                <Card>{store.plans.map(plan => this.renderPlan(plan))}</Card>
              </Layout.AnnotatedSection>
            </Layout>
          )}

          {store.toastMessage && (
            <Toast content={store.toastMessage} error={store.toastError} onDismiss={() => store.clearToast()} />
          )}
        </Frame>
      </div>
    );
  }
}

Account.wrappedComponent.propTypes = {
  store: PropTypes.object.isRequired
};
