import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';

import './WidgetPreview.css';

export default class WidgetPreview extends Component {
  renderCloseSvgIcon() {
    return (
      <span name="close" className="SVGInline svg-icon close-widget-button">
        <svg
          className="SVGInline-svg svg-icon-svg close-widget-button-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <line
            x1="0.5"
            y1="0.5"
            x2="23.5"
            y2="23.5"
            style={{ fill: 'none', strokeLinejoin: 'round', stroke: '#000' }}
          />
          <line
            x1="23.5"
            y1="0.5"
            x2="0.5"
            y2="23.5"
            style={{ fill: 'none', strokeLinejoin: 'round', stroke: '#000' }}
          />
        </svg>
      </span>
    );
  }

  render() {
    const {
      position,
      colors: { primary }
    } = this.props;
    const primaryDarkened = Color(primary).darken(0.1);

    /* eslint-disable react/no-danger */
    return (
      <div id="solvvy-shopify-widget-container" className="solvvy-shopify-widget-container widget-preview">
        <div className="solvvy-shopify-widget solvvy-cleanslate solvvy-support-flow">
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .solvvy-shopify-widget .launcher {
              ${position}: 15px !important;
              background-color: ${primary} !important;
              color: #fff !important;
            }

            .solvvy-shopify-widget .launcher:hover {
              background-color: ${primaryDarkened} !important;
            }

            .solvvy-shopify-widget .launcher.opened svg line {
              stroke: #fff !important;
            }

            .solvvy-shopify-widget .widget-container {
              ${position}: 0 !important;
            }

            .solvvy-shopify-widget .primary-color {
              color: ${primary} !important;
            }

            .solvvy-shopify-widget .workflow h1 {
              color: ${primary} !important;
            }

            .solvvy-shopify-widget .workflow svg.back-button-icon-svg path,
            .solvvy-shopify-widget svg.close-widget-button-svg line {
              stroke: ${primary} !important;
            }
            `
            }}
          />
          <div className="widget-container">
            <div className="widget">
              {this.renderCloseSvgIcon()}
              <div className="workflow">
                <div className="workflow-content">
                  <h1>Title Text</h1>
                  <div className="instructions">
                    <p>This is some body text. All body text is a standard grey color.</p>
                    <p>
                      The primary color determines the accent color for form elements, titles and the floating button
                      that opens this widget.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button className="launcher opened">{this.renderCloseSvgIcon()}</button>
        </div>
      </div>
    );
    /* eslint-enable react/no-danger */
  }
}
WidgetPreview.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  colors: PropTypes.shape({
    primary: PropTypes.string.isRequired
  }).isRequired
};
