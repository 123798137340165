// safe redirect for Shopify embedded app
const shopifyRedirect = (shop, url) => {
  // If the current window is the 'parent', change the URL by setting location.href
  if (window.top === window.self) {
    window.top.location.assign(url);
    // If the current window is the 'child', change the parent's URL with postMessage
  } else {
    const message = JSON.stringify({
      message: 'Shopify.API.remoteRedirect',
      data: { location: url }
    });
    window.parent.postMessage(message, `https://${shop}`);
  }
};

export default shopifyRedirect;
